import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

import { addContact } from 'lib/firebase';

const EmailSignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('That email is invalid.')
    .required('An email is required.'),
});

const EMAIL_SIGNUP_PROMPT = "Your email is kept securely and confidentially. You can unsubscribe at any time.";
const EMAIL_SIGNUP_ADDED = "Thanks! You should get an email soon if you aren't already subscribed."

export default withStyles({
  spanLink: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
})(function Index({ classes }) {
  return (
    <div>
      <Typography variant='h5'>
        Hey. {"I'm"} Edmund, the Hokage at{' '}
        <Box component='span' display='inline' fontWeight='fontWeightBold'>
         <Link
           className={classes.spanLink}
           color='secondary'
           underline='none'
           href='/labs'>
          Korley Labs
         </Link>
        </Box>.<br /><br />Alum of the{' '}
        <Box component='span' display='inline' fontWeight='fontWeightBold'>
          <Link
            className={classes.spanLink}
            target='_blank'
            rel='noopener'
            color='secondary'
            underline='none'
            href='https://chanzuckerberg.com/justice-opportunity/'>
            Chan Zuckerberg Initiative
          </Link>
        </Box>,{' '}
        <Box component='span' display='inline' fontWeight='fontWeightBold'>
          <Link
            className={classes.spanLink}
            target='_blank'
            rel='noopener'
            color='secondary'
            underline='none'
            href='https://www.codeforamerica.org/'>
            Code for America
          </Link>
        </Box>, and the{' '}
        <Box component='span' display='inline' fontWeight='fontWeightBold'>
          <Link
            className={classes.spanLink}
            target='_blank'
            rel='noopener'
            color='secondary'
            underline='none'
            href='https://www.recurse.com/'>
            Recurse Center
          </Link>
        </Box>.
      </Typography>
      <br />
      <Typography variant='h5'>
        My goal is help realize the potential of software in working towards global <Box display='inline' fontWeight='fontWeightBold'>economic</Box> and <Box display='inline' fontWeight='fontWeightBold'>social justice</Box>. Still figuring out what this looks like for me {"¯\\_(ツ)_/¯"}
      </Typography>
      <br />
      <Typography variant='h5'>
      {"I'm"} always interested in talking software. Feel free to just{' '}
        <Box component='span' display='inline' fontWeight='fontWeightBold'>
          <Link
            className={classes.spanLink}
            target='_blank'
            rel='noopener'
            color='secondary'
            underline='none'
            href='mailto:edmund@korley.net?subject=Hey'>
            email me
          </Link>
        </Box>!
      </Typography>
      <Box my={4}>
        <Divider />
      </Box>
      <Formik
        initialValues={{
          email: ''
        }}
        initialStatus={{ success: false }}
        validateOnMount={true}
        validationSchema={EmailSignupSchema}
        onSubmit={async ({ email }, { setStatus, setSubmitting, setErrors }) => {
          try {
            const _ = await addContact({ email });
            setStatus({ success: true });
          } catch (err) {
            setStatus({ success: false });
            setErrors({ email: 'Something when wrong. Try again later.' });
            throw err;
          } finally {
            setSubmitting(false);
          }
        }}>
        {
          ({ errors, status, isSubmitting, submitForm }) => (
            <Card>
              <Form>
                <CardContent>
                  <Box my={1} fontWeight="fontWeightBold">
                    <Typography variant="h6">Want to hear my thoughts on society and technology?</Typography>
                    <Typography variant="subtitle2">
                      I'm starting a blog! Please subscribe below or via{' '}
                      <Box component='span' display='inline' fontWeight='fontWeightBold'>
                        <Link
                          className={classes.spanLink}
                          target='_blank'
                          rel='noopener'
                          color='secondary'
                          underline='none'
                          href='/rss.xml'>RSS</Link>
                      </Box> to stay updated.
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Field
                      fullWidth
                      name="email"
                      type="email"
                      variant="filled"
                      color="secondary"
                      label="Email"
                      placeholder="you're@awesome.com"
                      helperText={status.success ? EMAIL_SIGNUP_ADDED : EMAIL_SIGNUP_PROMPT}
                      component={TextField}
                    />
                  </Box>
                </CardContent>
                <CardActions>
                  <Box ml={1} mb={1}>
                    <Button
                      disableElevation
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={(Boolean(errors.email) || isSubmitting || typeof window === 'undefined')}
                      onClick={submitForm}
                    >
                      <Typography variant='body2'>
                        <Box component='span' fontWeight="fontWeightMedium" fontFamily='"Poppins"'>Subscribe</Box>
                      </Typography>
                    </Button>
                  </Box>
                </CardActions>
              </Form>
              <LinearProgress variant={isSubmitting ? "indeterminate" : "determinate"} value={100} color="secondary" />
            </Card>
          )
        }
      </Formik>
      <Box mt={4} mb={2}>
        <Divider />
      </Box>
      <Box mb={2} fontWeight='fontWeightBold'>
        <Grid container justify='center' spacing={1}>
          <Grid item>
            <Typography variant='subtitle1'>
              <Link
                target='_blank'
                rel='noopener'
                color='primary'
                underline='none'
                href='https://github.com/EdmundKorley'>GitHub</Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1' color='primary'>|</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1'>
              <Link
                  target='_blank'
                  rel='noopener'
                  color='primary'
                  underline='none'
                  href='https://twitter.com/EdmundKorley'>Twitter</Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1' color='primary'>|</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1'>
              <Link
                  target='_blank'
                  rel='noopener'
                  color='primary'
                  underline='none'
                  href='https://linkedin.com/in/EdmundKorley'>LinkedIn</Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container justify='center'>
          <Typography variant='caption'>
            Copyright © 2020 Korley Labs, LLC. All Rights Reserved.
          </Typography>
        </Grid>
      </Box>
    </div>
  );
});
